import { default as affiliatesvq7kB0I01GMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsMECL3NhtWVMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngosxJXWJeQEZvMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_935C1GRzHiZtMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as indexLRdC9pWkwSMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93dPXzgx5K97Meta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as indexat9wRhdigAMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93fuoUZixQeuMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93bWBGQaNd2UMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as editubYpx0g3YDMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index232aFCbtfbMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsSTsjRxQkqEMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexb7ZkD2A6DRMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as index9UQjVcyDOFMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as share2OSAl98e3aMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showlvl2MM5z5SMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexzxM0eLM53GMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsKV3bl7lv8RMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guidesX4xLMgSJFxMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homeyd78FyAo4rMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexNF5gJ2QNf8Meta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationsqLO9AhsjXZMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as loginvFtOmuclkYMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templatesOmmzOJkNIoMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencersmrScAVBxsRMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_93CXM3zofMK9Meta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackfdzzALh5CEMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callbackW7YMQs0n1BMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbackwMpe2N0LwxMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnersGLXMGVRrgwMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93vAWmkAlHAiMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexATSpd1VDGSMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingnjEirdB2nsMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policySg5RJ4uhBAMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as registernxhZ4kddSpMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokensFx7ES6od8EMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountpwz81R8cA3Meta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminXtorQ6YSYnMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingdPmcVrV3JpMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsjqPRqUJ2kfMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainrji5gtjfYlMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as indexjkjpUnpLWQMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordSA5HqfRhRjMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profileDgf7kamilpMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesLpbJ0n0ZjnMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsNBxYbgUyaPMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/settings.vue?macro=true";
import { default as error1SlpTEtJANMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successO519QKtLiYMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsli030YXvhZMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as editj5hS1FGhQKMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indexdRAvjQxOPKMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as indexj90HGXXTrhMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as share6e4OCgmOk4Meta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showoDxLxcPPXxMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as index0DcPCYbqUYMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as index7PU977QsmZMeta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadso5djlJKWC0Meta } from "/codebuild/output/src1480709754/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliatesvq7kB0I01GMeta?.name ?? "affiliates",
    path: affiliatesvq7kB0I01GMeta?.path ?? "/affiliates",
    meta: affiliatesvq7kB0I01GMeta || {},
    alias: affiliatesvq7kB0I01GMeta?.alias || [],
    redirect: affiliatesvq7kB0I01GMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewsMECL3NhtWVMeta?.name ?? "database-views",
    path: database_45viewsMECL3NhtWVMeta?.path ?? "/database-views",
    meta: database_45viewsMECL3NhtWVMeta || {},
    alias: database_45viewsMECL3NhtWVMeta?.alias || [],
    redirect: database_45viewsMECL3NhtWVMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngosxJXWJeQEZvMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngosxJXWJeQEZvMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngosxJXWJeQEZvMeta || {},
    alias: discount_45students_45academics_45ngosxJXWJeQEZvMeta?.alias || [],
    redirect: discount_45students_45academics_45ngosxJXWJeQEZvMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935C1GRzHiZtMeta?.name ?? "features-slug",
    path: _91slug_935C1GRzHiZtMeta?.path ?? "/features/:slug()",
    meta: _91slug_935C1GRzHiZtMeta || {},
    alias: _91slug_935C1GRzHiZtMeta?.alias || [],
    redirect: _91slug_935C1GRzHiZtMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLRdC9pWkwSMeta?.name ?? "features",
    path: indexLRdC9pWkwSMeta?.path ?? "/features",
    meta: indexLRdC9pWkwSMeta || {},
    alias: indexLRdC9pWkwSMeta?.alias || [],
    redirect: indexLRdC9pWkwSMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dPXzgx5K97Meta?.name ?? "form-templates-slug",
    path: _91slug_93dPXzgx5K97Meta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93dPXzgx5K97Meta || {},
    alias: _91slug_93dPXzgx5K97Meta?.alias || [],
    redirect: _91slug_93dPXzgx5K97Meta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexat9wRhdigAMeta?.name ?? "form-templates",
    path: indexat9wRhdigAMeta?.path ?? "/form-templates",
    meta: indexat9wRhdigAMeta || {},
    alias: indexat9wRhdigAMeta?.alias || [],
    redirect: indexat9wRhdigAMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fuoUZixQeuMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_93fuoUZixQeuMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93fuoUZixQeuMeta || {},
    alias: _91slug_93fuoUZixQeuMeta?.alias || [],
    redirect: _91slug_93fuoUZixQeuMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bWBGQaNd2UMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93bWBGQaNd2UMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93bWBGQaNd2UMeta || {},
    alias: _91slug_93bWBGQaNd2UMeta?.alias || [],
    redirect: _91slug_93bWBGQaNd2UMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: editubYpx0g3YDMeta?.name ?? "forms-slug-edit",
    path: editubYpx0g3YDMeta?.path ?? "/forms/:slug()/edit",
    meta: editubYpx0g3YDMeta || {},
    alias: editubYpx0g3YDMeta?.alias || [],
    redirect: editubYpx0g3YDMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: index232aFCbtfbMeta?.name ?? "forms-slug",
    path: index232aFCbtfbMeta?.path ?? "/forms/:slug()",
    meta: index232aFCbtfbMeta || {},
    alias: index232aFCbtfbMeta?.alias || [],
    redirect: index232aFCbtfbMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showlvl2MM5z5SMeta?.name ?? undefined,
    path: showlvl2MM5z5SMeta?.path ?? "/forms/:slug()/show",
    meta: showlvl2MM5z5SMeta || {},
    alias: showlvl2MM5z5SMeta?.alias || [],
    redirect: showlvl2MM5z5SMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analyticsSTsjRxQkqEMeta?.name ?? "forms-slug-show-analytics",
    path: analyticsSTsjRxQkqEMeta?.path ?? "analytics",
    meta: analyticsSTsjRxQkqEMeta || {},
    alias: analyticsSTsjRxQkqEMeta?.alias || [],
    redirect: analyticsSTsjRxQkqEMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexb7ZkD2A6DRMeta?.name ?? "forms-slug-show",
    path: indexb7ZkD2A6DRMeta?.path ?? "",
    meta: indexb7ZkD2A6DRMeta || {},
    alias: indexb7ZkD2A6DRMeta?.alias || [],
    redirect: indexb7ZkD2A6DRMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: index9UQjVcyDOFMeta?.name ?? "forms-slug-show-integrations",
    path: index9UQjVcyDOFMeta?.path ?? "integrations",
    meta: index9UQjVcyDOFMeta || {},
    alias: index9UQjVcyDOFMeta?.alias || [],
    redirect: index9UQjVcyDOFMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: share2OSAl98e3aMeta?.name ?? "forms-slug-show-share",
    path: share2OSAl98e3aMeta?.path ?? "share",
    meta: share2OSAl98e3aMeta || {},
    alias: share2OSAl98e3aMeta?.alias || [],
    redirect: share2OSAl98e3aMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzxM0eLM53GMeta?.name ?? "forms-create",
    path: indexzxM0eLM53GMeta?.path ?? "/forms/create",
    meta: indexzxM0eLM53GMeta || {},
    alias: indexzxM0eLM53GMeta?.alias || [],
    redirect: indexzxM0eLM53GMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsKV3bl7lv8RMeta?.name ?? "forms-uploads",
    path: uploadsKV3bl7lv8RMeta?.path ?? "/forms/uploads",
    meta: uploadsKV3bl7lv8RMeta || {},
    alias: uploadsKV3bl7lv8RMeta?.alias || [],
    redirect: uploadsKV3bl7lv8RMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guidesX4xLMgSJFxMeta?.name ?? "guides",
    path: guidesX4xLMgSJFxMeta?.path ?? "/guides",
    meta: guidesX4xLMgSJFxMeta || {},
    alias: guidesX4xLMgSJFxMeta?.alias || [],
    redirect: guidesX4xLMgSJFxMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homeyd78FyAo4rMeta?.name ?? "home",
    path: homeyd78FyAo4rMeta?.path ?? "/home",
    meta: homeyd78FyAo4rMeta || {},
    alias: homeyd78FyAo4rMeta?.alias || [],
    redirect: homeyd78FyAo4rMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexNF5gJ2QNf8Meta?.name ?? "index",
    path: indexNF5gJ2QNf8Meta?.path ?? "/",
    meta: indexNF5gJ2QNf8Meta || {},
    alias: indexNF5gJ2QNf8Meta?.alias || [],
    redirect: indexNF5gJ2QNf8Meta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsqLO9AhsjXZMeta?.name ?? "integrations",
    path: integrationsqLO9AhsjXZMeta?.path ?? "/integrations",
    meta: integrationsqLO9AhsjXZMeta || {},
    alias: integrationsqLO9AhsjXZMeta?.alias || [],
    redirect: integrationsqLO9AhsjXZMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: loginvFtOmuclkYMeta?.name ?? "login",
    path: loginvFtOmuclkYMeta?.path ?? "/login",
    meta: loginvFtOmuclkYMeta || {},
    alias: loginvFtOmuclkYMeta?.alias || [],
    redirect: loginvFtOmuclkYMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templatesOmmzOJkNIoMeta?.name ?? "my-templates",
    path: my_45templatesOmmzOJkNIoMeta?.path ?? "/my-templates",
    meta: my_45templatesOmmzOJkNIoMeta || {},
    alias: my_45templatesOmmzOJkNIoMeta?.alias || [],
    redirect: my_45templatesOmmzOJkNIoMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencersmrScAVBxsRMeta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencersmrScAVBxsRMeta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencersmrScAVBxsRMeta || {},
    alias: notion_45ambassadors_45influencersmrScAVBxsRMeta?.alias || [],
    redirect: notion_45ambassadors_45influencersmrScAVBxsRMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CXM3zofMK9Meta?.name ?? "notion-form-slug",
    path: _91slug_93CXM3zofMK9Meta?.path ?? "/notion-form/:slug()",
    meta: _91slug_93CXM3zofMK9Meta || {},
    alias: _91slug_93CXM3zofMK9Meta?.alias || [],
    redirect: _91slug_93CXM3zofMK9Meta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbackfdzzALh5CEMeta?.name ?? "notion-callback",
    path: callbackfdzzALh5CEMeta?.path ?? "/notion/callback",
    meta: callbackfdzzALh5CEMeta || {},
    alias: callbackfdzzALh5CEMeta?.alias || [],
    redirect: callbackfdzzALh5CEMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callbackW7YMQs0n1BMeta?.name ?? "notion-guest-callback",
    path: guest_45callbackW7YMQs0n1BMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callbackW7YMQs0n1BMeta || {},
    alias: guest_45callbackW7YMQs0n1BMeta?.alias || [],
    redirect: guest_45callbackW7YMQs0n1BMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbackwMpe2N0LwxMeta?.name ?? "oauth-callback",
    path: callbackwMpe2N0LwxMeta?.path ?? "/oauth/callback",
    meta: callbackwMpe2N0LwxMeta || {},
    alias: callbackwMpe2N0LwxMeta?.alias || [],
    redirect: callbackwMpe2N0LwxMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnersGLXMGVRrgwMeta?.name ?? "partners",
    path: partnersGLXMGVRrgwMeta?.path ?? "/partners",
    meta: partnersGLXMGVRrgwMeta || {},
    alias: partnersGLXMGVRrgwMeta?.alias || [],
    redirect: partnersGLXMGVRrgwMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93vAWmkAlHAiMeta?.name ?? "password-reset-token",
    path: _91token_93vAWmkAlHAiMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93vAWmkAlHAiMeta || {},
    alias: _91token_93vAWmkAlHAiMeta?.alias || [],
    redirect: _91token_93vAWmkAlHAiMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexATSpd1VDGSMeta?.name ?? "password-reset",
    path: indexATSpd1VDGSMeta?.path ?? "/password/reset",
    meta: indexATSpd1VDGSMeta || {},
    alias: indexATSpd1VDGSMeta?.alias || [],
    redirect: indexATSpd1VDGSMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricingnjEirdB2nsMeta?.name ?? "pricing",
    path: pricingnjEirdB2nsMeta?.path ?? "/pricing",
    meta: pricingnjEirdB2nsMeta || {},
    alias: pricingnjEirdB2nsMeta?.alias || [],
    redirect: pricingnjEirdB2nsMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policySg5RJ4uhBAMeta?.name ?? "privacy-policy",
    path: privacy_45policySg5RJ4uhBAMeta?.path ?? "/privacy-policy",
    meta: privacy_45policySg5RJ4uhBAMeta || {},
    alias: privacy_45policySg5RJ4uhBAMeta?.alias || [],
    redirect: privacy_45policySg5RJ4uhBAMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registernxhZ4kddSpMeta?.name ?? "register",
    path: registernxhZ4kddSpMeta?.path ?? "/register",
    meta: registernxhZ4kddSpMeta || {},
    alias: registernxhZ4kddSpMeta?.alias || [],
    redirect: registernxhZ4kddSpMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsNBxYbgUyaPMeta?.name ?? undefined,
    path: settingsNBxYbgUyaPMeta?.path ?? "/settings",
    meta: settingsNBxYbgUyaPMeta || {},
    alias: settingsNBxYbgUyaPMeta?.alias || [],
    redirect: settingsNBxYbgUyaPMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokensFx7ES6od8EMeta?.name ?? "settings-access-tokens",
    path: access_45tokensFx7ES6od8EMeta?.path ?? "access-tokens",
    meta: access_45tokensFx7ES6od8EMeta || {},
    alias: access_45tokensFx7ES6od8EMeta?.alias || [],
    redirect: access_45tokensFx7ES6od8EMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountpwz81R8cA3Meta?.name ?? "settings-account",
    path: accountpwz81R8cA3Meta?.path ?? "account",
    meta: accountpwz81R8cA3Meta || {},
    alias: accountpwz81R8cA3Meta?.alias || [],
    redirect: accountpwz81R8cA3Meta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminXtorQ6YSYnMeta?.name ?? "settings-admin",
    path: adminXtorQ6YSYnMeta?.path ?? "admin",
    meta: adminXtorQ6YSYnMeta || {},
    alias: adminXtorQ6YSYnMeta?.alias || [],
    redirect: adminXtorQ6YSYnMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingdPmcVrV3JpMeta?.name ?? "settings-billing",
    path: billingdPmcVrV3JpMeta?.path ?? "billing",
    meta: billingdPmcVrV3JpMeta || {},
    alias: billingdPmcVrV3JpMeta?.alias || [],
    redirect: billingdPmcVrV3JpMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsjqPRqUJ2kfMeta?.name ?? "settings-connections",
    path: connectionsjqPRqUJ2kfMeta?.path ?? "connections",
    meta: connectionsjqPRqUJ2kfMeta || {},
    alias: connectionsjqPRqUJ2kfMeta?.alias || [],
    redirect: connectionsjqPRqUJ2kfMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainrji5gtjfYlMeta?.name ?? "settings-custom-domain",
    path: custom_45domainrji5gtjfYlMeta?.path ?? "custom-domain",
    meta: custom_45domainrji5gtjfYlMeta || {},
    alias: custom_45domainrji5gtjfYlMeta?.alias || [],
    redirect: custom_45domainrji5gtjfYlMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: indexjkjpUnpLWQMeta?.name ?? "settings",
    path: indexjkjpUnpLWQMeta?.path ?? "",
    meta: indexjkjpUnpLWQMeta || {},
    alias: indexjkjpUnpLWQMeta?.alias || [],
    redirect: indexjkjpUnpLWQMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordSA5HqfRhRjMeta?.name ?? "settings-password",
    path: passwordSA5HqfRhRjMeta?.path ?? "password",
    meta: passwordSA5HqfRhRjMeta || {},
    alias: passwordSA5HqfRhRjMeta?.alias || [],
    redirect: passwordSA5HqfRhRjMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileDgf7kamilpMeta?.name ?? "settings-profile",
    path: profileDgf7kamilpMeta?.path ?? "profile",
    meta: profileDgf7kamilpMeta || {},
    alias: profileDgf7kamilpMeta?.alias || [],
    redirect: profileDgf7kamilpMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacesLpbJ0n0ZjnMeta?.name ?? "settings-workspaces",
    path: workspacesLpbJ0n0ZjnMeta?.path ?? "workspaces",
    meta: workspacesLpbJ0n0ZjnMeta || {},
    alias: workspacesLpbJ0n0ZjnMeta?.alias || [],
    redirect: workspacesLpbJ0n0ZjnMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: error1SlpTEtJANMeta?.name ?? "subscriptions-error",
    path: error1SlpTEtJANMeta?.path ?? "/subscriptions/error",
    meta: error1SlpTEtJANMeta || {},
    alias: error1SlpTEtJANMeta?.alias || [],
    redirect: error1SlpTEtJANMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successO519QKtLiYMeta?.name ?? "subscriptions-success",
    path: successO519QKtLiYMeta?.path ?? "/subscriptions/success",
    meta: successO519QKtLiYMeta || {},
    alias: successO519QKtLiYMeta?.alias || [],
    redirect: successO519QKtLiYMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsli030YXvhZMeta?.name ?? "terms-conditions",
    path: terms_45conditionsli030YXvhZMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsli030YXvhZMeta || {},
    alias: terms_45conditionsli030YXvhZMeta?.alias || [],
    redirect: terms_45conditionsli030YXvhZMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: editj5hS1FGhQKMeta?.name ?? "views-slug-edit",
    path: editj5hS1FGhQKMeta?.path ?? "/views/:slug()/edit",
    meta: editj5hS1FGhQKMeta || {},
    alias: editj5hS1FGhQKMeta?.alias || [],
    redirect: editj5hS1FGhQKMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexdRAvjQxOPKMeta?.name ?? "views-slug",
    path: indexdRAvjQxOPKMeta?.path ?? "/views/:slug()",
    meta: indexdRAvjQxOPKMeta || {},
    alias: indexdRAvjQxOPKMeta?.alias || [],
    redirect: indexdRAvjQxOPKMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showoDxLxcPPXxMeta?.name ?? undefined,
    path: showoDxLxcPPXxMeta?.path ?? "/views/:slug()/show",
    meta: showoDxLxcPPXxMeta || {},
    alias: showoDxLxcPPXxMeta?.alias || [],
    redirect: showoDxLxcPPXxMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexj90HGXXTrhMeta?.name ?? "views-slug-show",
    path: indexj90HGXXTrhMeta?.path ?? "",
    meta: indexj90HGXXTrhMeta || {},
    alias: indexj90HGXXTrhMeta?.alias || [],
    redirect: indexj90HGXXTrhMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: share6e4OCgmOk4Meta?.name ?? "views-slug-show-share",
    path: share6e4OCgmOk4Meta?.path ?? "share",
    meta: share6e4OCgmOk4Meta || {},
    alias: share6e4OCgmOk4Meta?.alias || [],
    redirect: share6e4OCgmOk4Meta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index0DcPCYbqUYMeta?.name ?? "views-create",
    path: index0DcPCYbqUYMeta?.path ?? "/views/create",
    meta: index0DcPCYbqUYMeta || {},
    alias: index0DcPCYbqUYMeta?.alias || [],
    redirect: index0DcPCYbqUYMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: index7PU977QsmZMeta?.name ?? "views",
    path: index7PU977QsmZMeta?.path ?? "/views",
    meta: index7PU977QsmZMeta || {},
    alias: index7PU977QsmZMeta?.alias || [],
    redirect: index7PU977QsmZMeta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadso5djlJKWC0Meta?.name ?? "views-uploads",
    path: uploadso5djlJKWC0Meta?.path ?? "/views/uploads",
    meta: uploadso5djlJKWC0Meta || {},
    alias: uploadso5djlJKWC0Meta?.alias || [],
    redirect: uploadso5djlJKWC0Meta?.redirect,
    component: () => import("/codebuild/output/src1480709754/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]